export const SET_GQL_TOKEN = (state, data) => {
    state.gqlToken = data;
}

export const SET_CSRF = (state, data) => {
    state.csrf = data;
}

export const SET_NEWS = (state, data) => {
    state.news = data;
}
