<script>

    import { defineAsyncComponent, defineComponent } from 'vue'
    import { mapGetters } from 'vuex'

    export default defineComponent({

        components: {
            'notification--cookie': defineAsyncComponent(() => import(/* webpackChunkName: "notifications" */ '@/vue/molecules/notifications/notification--cookie.vue')),
            'cards--slider': defineAsyncComponent(() => import(/* webpackChunkName: "cards-slider" */ '@/vue/organisms/cards/cards--slider.vue')),
            'cards--slider-full': defineAsyncComponent(() => import(/* webpackChunkName: "cards-slider-full" */ '@/vue/organisms/cards/cards--slider-full.vue')),
            'cards--slider-highlight': defineAsyncComponent(() => import(/* webpackChunkName: "cards-slider-highlight" */ '@/vue/organisms/cards/cards--slider-highlight.vue')),
            'news-flash--slider': defineAsyncComponent(() => import(/* webpackChunkName: "news-flash-slider" */ '@/vue/organisms/news-flash/news-flash--slider.vue')),
            'expo--slider': defineAsyncComponent(() => import(/* webpackChunkName: "expo-slider" */ '@/vue/organisms/expo/expo--slider.vue')),
            'image--slider': defineAsyncComponent(() => import(/* webpackChunkName: "image-slider" */ '@/vue/organisms/image-slider/image--slider.vue')),
            'accordion--list': defineAsyncComponent(() => import(/* webpackChunkName: "accordion-list" */ '@/vue/organisms/accordion-list/accordion--list.vue')),
            'tag--filter': defineAsyncComponent(() => import(/* webpackChunkName: "tag-filter" */ '@/vue/organisms/filter/tag--filter.vue')),
            'language--switcher': defineAsyncComponent(() => import(/* webpackChunkName: "language-switcher" */ '@/vue/organisms/language-switcher/language--switcher.vue')),
            'floorplan': defineAsyncComponent(() => import(/* webpackChunkName: "language-switcher" */ '@/vue/organisms/floorplan/floorplan.vue')),
        },

        data: () => ({}),

        computed: {
            ...mapGetters([
                'GQL_TOKEN'
            ])
        },

        methods: {

            printPage() {
                window.print();
            }

        },

        async mounted(){

            if(!this.GQL_TOKEN) {
                await this.$store.dispatch('FETCH_CSRF')
                await this.$store.dispatch('FETCH_GQL_TOKEN')
            }

            // await this.$store.dispatch("FETCH_PLACEHOLDERS")
        }
    })

</script>
