export const CSRF_TOKEN = (state) => {
    return state.csrf;
}

export const GQL_TOKEN = (state) => {
    return state.gqlToken;
}

export const NEWS = (state) => {
    return state.news;
}
